import React, { useState, useRef } from "react";
import "./LoginSignup.css";
import { Form } from "reactstrap";
import { Link } from "react-router-dom";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { FloatLabel } from "primereact/floatlabel";
import { Toast } from "primereact/toast";
import images from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import { ProgressSpinner } from "primereact/progressspinner";

const ForgotPassword = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  //Setting the title of the page

  document.title = "DOCPI | FORGOT PASSWORD";

  const [values, setValues] = useState({
    email: "",
  });

  //input handler
  const handleInput = (e) => {
    setValues({ ...values, [e.target.name]: [e.target.value] });
  };

  const handleValidation = (e) => {
    //email sending login
  };

  //creating function to post data on srever

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // sending request
    //  let resp = await  myAxios.post(`http://localhost:8080/api/users/forgot/password?email=${values.email}`).then((res)=> res.data).catch((e)=> e.response.data)

    const axiosInstance = await myAxios();
    let resp = await axiosInstance
      .post(`users/forgot/password?email=${values.email}`)
      .then((res) => res.data)
      .catch((e) => e.response.data);
    // console.log(resp);
    // console.log(resp.status);

    if (resp.status === 404) {
      toast.current.show({
        severity: "error",
        summary: "Invalid Email",
        detail: resp.message,
        life: 1000,
      });

      // console.log(resp.message);
    } else if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "OTP Sent",
        detail: resp.message,
        life: 1000,
      });

      setTimeout(() => {
        navigate("/verify");
        setLoading(false);
      }, 1000);

      // console.log(resp.message);
      localStorage.setItem("otpemail", values.email);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column w-screen h-screen overflow-y-auto surface-50">
        <div className="w-12 md:w-4 lg:w-4 m-auto">
          <div className="flex justify-content-center w-full">
            <Card className="w-full shadow-3 hover:shadow-5 border-round-lg">
              <Form onSubmit={handleValidation}>
                <div className="flex flex-column align-items-start justify-content-start gap-3 p-4 w-full">
                  <img
                    src={images.logo}
                    alt="logo"
                    className="login-logo m-auto"
                  />
                  <span className="text-2xl font-bold my-3 text-center w-full">
                    Forgot Password
                  </span>

                  <div className="w-full my-2">
                    <FloatLabel className="w-full">
                      <InputText
                        id="email"
                        name="email"
                        onChange={handleInput}
                        className="p-inputtext-lg w-full border-round-lg"
                      />
                      <label htmlFor="email">Email Address</label>
                    </FloatLabel>
                  </div>

                  <div className="flex flex-column align-items-stretch justify-content-start gap-3 w-full">
                    <Button
                      label="Submit"
                      severity="primary"
                      type="submit"
                      onClick={handleSubmit}
                      className="border-round-lg"
                    />
                    {loading && (
                      <div className="flex align-items-center">
                        <ProgressSpinner
                          style={{ width: "30px", height: "30px" }}
                        />{" "}
                        {/* Set smaller size */}
                      </div>
                    )}
                    <div className="flex flex-row align-items-center justify-content-between">
                      <Link to="/login" className="no-underline">
                        Back to Login
                      </Link>
                    </div>
                  </div>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgotPassword;
