import React, { useEffect, useState, useRef } from "react";
import "./LoginSignup.css";
import { Form } from "reactstrap";
import Validation_Login from "./Validation_Login";
import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "../contextAPIs/UserContext";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Password } from "primereact/password";
import { FloatLabel } from "primereact/floatlabel";
import { Toast } from "primereact/toast";
import images from "../../assets/images";
import toastrService from "../../services/Toastr";
import UserRegistration from "../../Pages/UserRegistration";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import { jwtDecode } from "jwt-decode";

const Login = () => {
  //Setting the title of the page
  const toast = useRef(null);

  useEffect(() => {
    toastrService.setToastRef(toast.current);
    document.title = "DOCPI-LOGIN | Vaanara Technologies";
  }, []);

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  //errors for the invalid input field //
  const [errors, setErrors] = useState({});

  //context api for user login
  const { login } = useUserContext();

  //input handler
  const handleChange = (e, property) => {
    setValues({
      ...values,
      [property]: e.target.value,
    });
  };
  //to navigate from login to home page
  const navigate = useNavigate();

  //handling the valdation
  const handleNavigatin = async (userEmail) => {
    try {
      const axiosInstance = await myAxios();
      const userRoleData = await axiosInstance.get(
        `users/edit/getuser/${userEmail}`
      );
      localStorage.setItem("userRole", userRoleData.data.permissions);
      localStorage.setItem("userName", userRoleData.data.firstName);
      localStorage.setItem("company", userRoleData.data.company);

      setTimeout(() => {
        navigate("/home");
      }, 1500);
    } catch (err) {
      if (err.response) {
        toast.current.show({
          severity: "error",
          summary: "Error",
          detail: err.response.data,
          life: 3000,
        });
      }
    }
  };

  // handle the validation
  const handleValidation = async (e) => {
    e.preventDefault();

    // console.log("you pressed validataion ");
    // localStorage.removeItem("accessToken");
    localStorage.clear();
    const axiosInstance = await myAxios();
    try {
      const authData = await axiosInstance
        .post("/authenticate", values)
        .then((res) => res.data);

      // console.log(authData);
      // console.log(authData['jwt'])
      // console.log(authData['id'])
      // console.log(authData['user'])

      if (authData["jwt"] !== undefined) {
        localStorage.setItem("accessToken", authData["jwt"]);
        localStorage.setItem("user", authData["user"]);
        localStorage.setItem("id", authData["id"]);

        toast.current.show({
          severity: "success",
          summary: "Login",
          detail: "Login Success! ",
          life: 1000,
        });
      }

      try {
        // extract the email from the jwt token
        const decodedToken = jwtDecode(authData["jwt"]);
        let userEmail = decodedToken.sub;
        localStorage.setItem("userId", userEmail);
        const msg = "Login Successful";
        handleNavigatin(userEmail);
        // setTimeout(() => {
        //   handleNavigatin(userEmail);
        // }, 2000);
      } catch (error) {
        console.error("Invalid token", error);
      }
    } catch (e) {
      if (e.response.status === 401) {
        toast.current.show({
          severity: "error",
          summary: "Login Failed",
          detail: "Invalid Username / Password ",
          life: 3000,
        });
      } else if (e.response && e.response.status === 403) {
        toast.current.show({
          severity: "error",
          detail: e.response.data,
          life: 4000,
        });
      }
    }
  };
  //to open the registration page
  const [isRegisterSidebarVisible, setRegisterSidebarVisible] = useState(false);
  const handleRegistration = () => {
    setRegisterSidebarVisible(true);
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column w-screen h-screen overflow-y-auto surface-50">
        <div className="w-12 md:w-4 lg:w-4 m-auto">
          <div className="flex justify-content-center w-full">
            <Card className="w-full shadow-3 hover:shadow-5 border-round-lg">
              <Form onSubmit={handleValidation}>
                <div className="flex flex-column align-items-start justify-content-start gap-3 p-4 w-full">
                  <img
                    src={images.logo}
                    alt="logo"
                    className="login-logo m-auto"
                  />
                  <span className="text-2xl font-bold my-3 text-center w-full">
                    Login
                  </span>

                  <div className="w-full my-2">
                    <FloatLabel className="w-full">
                      <InputText
                        id="email"
                        name="email"
                        onChange={(e) => handleChange(e, "email")}
                        className="p-inputtext-lg w-full border-round-lg"
                      />
                      <label htmlFor="emailId">Email</label>
                    </FloatLabel>
                    {errors.email && (
                      <span className="error-message text-xs">
                        {errors.email}
                      </span>
                    )}
                  </div>
                  <div className="w-full my-2">
                    <FloatLabel className="w-full">
                      <Password
                        id="password"
                        onChange={(e) => handleChange(e, "password")}
                        feedback={false}
                        toggleMask
                        className="p-inputtext-lg border-round-lg w-full"
                      />
                      <label htmlFor="password">Password</label>
                    </FloatLabel>
                    {errors.password && (
                      <span className="error-message text-xs">
                        {errors.password}
                      </span>
                    )}
                  </div>

                  <div className="flex flex-column align-items-stretch justify-content-start gap-3 w-full">
                    <Button
                      label="Login"
                      type="submit"
                      severity="primary"
                      onClick={Validation_Login}
                      className="border-round-lg"
                    />
                    {/* <div
                      className="flex flex-row align-items-center justify-content-between col"
                      style={{ color: "var(--surface-900)", cursor: "pointer" }}
                      onClick={handleRegistration}
                    >
                      Don't have an account?
                    </div> */}
                    <div
                      className="flex flex-row align-items-center justify-content-between col"
                      style={{ color: "var(--surface-900)" }}
                    >
                      <Link to="/forgot-password" className="no-underline">
                        Forgot Password?
                      </Link>
                    </div>
                  </div>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </div>
      {isRegisterSidebarVisible && (
        <UserRegistration onClose={setRegisterSidebarVisible} />
      )}
    </>
  );
};
export default Login;
