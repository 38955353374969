import React from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Link } from "react-router-dom";
import { useUserContext } from "./contextAPIs/UserContext";

const Breadcrumbs = () => {
  const items = [
    {
      label: "Rules",
      template: () => (
        <Link
          to="/rules/create"
          className="text-primary font-semibold no-underline hover:underline"
        >
          Create Jobs
        </Link>
      ),
    },
  ];
  const home = { icon: "pi pi-home", url: "/home" };
  const { getRole } = useUserContext();
  return (
    getRole() !== "PROD" && (
      <BreadCrumb
        style={{ border: "none", background: "none" }}
        model={items}
        home={home}
      />
    )
  );
};

export default Breadcrumbs;
