import React, { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./HomeNavbar.css";
import { useUserContext } from "../contextAPIs/UserContext";
import Profile from "../../Pages/Profile";
import UserRegistration from "../../Pages/UserRegistration";
import { Toolbar } from "primereact/toolbar";
import { Menu } from "primereact/menu";
import { Avatar } from "primereact/avatar";
import { Toast } from "primereact/toast";

const HomeNavbar = () => {
  const menuRight = useRef(null);
  const navigate = useNavigate();
  const toast = useRef(null);
  const { logout } = useUserContext();
  const [isProfileSidebarVisible, setIsProfileSidebarVisible] = useState(false);
  const [isRegisterSidebarVisible, setRegisterSidebarVisible] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const location = useLocation();

  const [isDocumentReview, setIsSetDocumentReview] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("userRole")?.includes("ADMIN")) {
      setIsAdmin(true);
    }
    if (location.pathname === "/document-review/home") {
      setIsSetDocumentReview(true);
    }
  }, []);

  const navigateToFirstApp = () => {
    setTimeout(() => {
      isDocumentReview
        ? (window.location.href = "/home")
        : (window.location.href = "/document-review/home");
      // Navigates and refreshes
    }, 100); // Delays navigation by 100ms
  };

  const items = [
    {
      label: "Profile",
      icon: "pi pi-user",
      command: () => {
        setIsProfileSidebarVisible(true);
      },
    },
    isAdmin && {
      label: "Register",
      icon: "pi pi-user-plus",
      command: () => {
        setRegisterSidebarVisible(true);
      },
    },
    {
      label: "Users",
      icon: "pi pi-users",
      command: () => {
        navigate("/users");
      },
    },
    isAdmin && {
      label: "Create Secret",
      icon: "pi pi-plus",
      command: () => {
        navigate("/createSecret");
      },
    },
    {
      label: "View Secret",
      icon: "pi pi-eye",
      command: () => {
        navigate("/viewsecret"); // Show the tree select when clicked
      },
    },
    {
      separator: true,
    },
    {
      label: "Logout",
      icon: "pi pi-power-off",
      command: () => {
        handleLogout();
      },
    },
  ].filter(Boolean);

  const handleLogout = () => {
    localStorage.clear();

    toast.current.show({
      severity: "success",
      summary: "Logout",
      detail: "You Successfully Logged Out!",
      life: 1000,
    });

    setTimeout(() => {
      navigate("/");
    }, 1000);
  };

  const handleProfileHideSidebar = () => {
    setIsProfileSidebarVisible(false);
  };

  const handleRegisterHideSidebar = () => {
    setRegisterSidebarVisible(false);
  };

  const startContent = (
    <React.Fragment>
      <Link to="/home" className="logo-header"></Link>
    </React.Fragment>
  );

  const endContent = (
    <React.Fragment>
      <Menu
        model={items}
        popup
        ref={menuRight}
        id="popup_menu_right"
        popupAlignment="right"
      />

      {isProfileSidebarVisible && (
        <Profile onClose={handleProfileHideSidebar} />
      )}
      {isRegisterSidebarVisible && (
        <UserRegistration onClose={handleRegisterHideSidebar} email="" />
      )}

      <ul className="menu list-none m-0 p-0 flex flex-row align-items-center justify-content-center gap-3 mx-5 text-base">
        <li>
          <Link
            onClick={navigateToFirstApp}
            className="no-underline font-semibold text-800 hover:text-900 active:text-primary"
          >
            {isDocumentReview === true ? "DocPi" : "Document Review"}
          </Link>
        </li>
        <li>
          <Link
            onClick={(event) => menuRight.current.toggle(event)}
            aria-controls="popup_menu_right"
            aria-haspopup
          >
            <Avatar icon="pi pi-user" shape="circle" />
          </Link>
        </li>
      </ul>
    </React.Fragment>
  );

  return (
    <>
      <Toast ref={toast} />
      <Toolbar
        start={startContent}
        end={endContent}
        className="border-noround"
      />
    </>
  );
};

export default HomeNavbar;
