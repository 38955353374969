import React, { useRef, useState } from "react";
import "./LoginSignup.css";
import { Form } from "reactstrap";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { FloatLabel } from "primereact/floatlabel";
import { Toast } from "primereact/toast";
import { useNavigate } from "react-router-dom";
import images from "../../assets/images";
import { createAxiosInstance as myAxios } from "../../services/Helper";
import { ProgressSpinner } from "primereact/progressspinner";

const Changepass = () => {
  const toast = useRef(null);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  let email = localStorage.getItem("otpemail");

  let [passwordData, setPasswordData] = useState({
    password: "",
    repassword: "",
  });

  // handle change
  const handleChange = (e) => {
    // console.log(e.target.value , e.target.name)
    setPasswordData({ ...passwordData, [e.target.name]: [e.target.value] });
  };

  // Handle confirm button click
  const handleConfirm = async (e) => {
    e.preventDefault();

    // console.log(passwordData.password[0] , passwordData.repassword[0]  , passwordData.password[0] == passwordData.repassword[0])

    if (passwordData.password[0] !== passwordData.repassword[0]) {
      toast.current.show({
        severity: "error",
        summary: "Password Check",
        detail: "Password and Re-enter Password Should Match",
        life: 1000,
      });

      return;
    }

    setLoading(true);
    // let resp = await myAxios.post(`http://localhost:8080/api/users/update/password?email=${email}&newPassword=${passwordData.password[0]}`).then((res)=> res.data).catch((e)=> e.response.data);
    const axiosInstance = await myAxios();
    let resp = await axiosInstance
      .post(
        `users/update/password?email=${email}&newPassword=${passwordData.password[0]}`
      )
      .then((res) => res.data)
      .catch((e) => e.response.data);

    //  console.log(resp)

    if (resp.status === 404) {
    } else if (resp.status === 200) {
      toast.current.show({
        severity: "success",
        summary: "Password Changed",
        detail: "Password has been successfully changed.",
        life: 1000,
      });

      localStorage.removeItem("otpemail");
      // Redirect to the login page after showing the toast message
      setTimeout(() => {
        navigate("/login");
        setLoading(false);
      }, 2000); // Delay should match the toast duration
    }
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="flex flex-column w-screen h-screen overflow-y-auto surface-50">
        <div className="w-12 md:w-4 lg:w-4 m-auto">
          <div className="flex justify-content-center w-full">
            <Card className="w-full shadow-3 hover:shadow-5 border-round-lg">
              <Form>
                <div className="flex flex-column align-items-start justify-content-start gap-3 p-4 w-full">
                  <img
                    src={images.logo}
                    alt="logo"
                    className="login-logo m-auto"
                  />
                  <span className="text-2xl font-bold my-3 text-center w-full">
                    Change Password
                  </span>

                  <div className="w-full my-2">
                    <FloatLabel className="w-full">
                      <Password
                        id="password"
                        name="password"
                        value={passwordData.password}
                        onChange={handleChange}
                        feedback={false}
                        toggleMask
                        className="p-inputtext-lg border-round-lg w-full"
                      />
                      <label htmlFor="password">New Password</label>
                    </FloatLabel>
                  </div>
                  <div className="w-full my-2">
                    <FloatLabel className="w-full">
                      <Password
                        id="repassword"
                        name="repassword"
                        value={passwordData.repassword}
                        feedback={false}
                        onChange={handleChange}
                        toggleMask
                        className="p-inputtext-lg border-round-lg w-full"
                      />
                      <label htmlFor="repassword">Re-enter New Password</label>
                    </FloatLabel>
                  </div>

                  <div className="flex flex-column align-items-stretch justify-content-start gap-3 w-full">
                    <Button
                      label="Confirm Password"
                      type="button"
                      severity="primary"
                      onClick={handleConfirm}
                      className="border-round-lg"
                    />
                    {loading && (
                      <div className="flex align-items-center">
                        <ProgressSpinner
                          style={{ width: "30px", height: "30px" }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </Form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default Changepass;
