import React, { createContext, useState } from "react";

const ContextTimeScheduling = createContext({
  openDialog: false,
  setOpenDialog: () => {},
  contextFormData: {},
  setcontextFormData: () => {}, // Default empty function
  setSelectedViewAs: () => {},
});

const TimeSchedulingProvider = ({ children }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [contextFormData, setcontextFormData] = useState({});
  const [selectedViewAs, setSelectedViewAs] = useState("");

  return (
    <ContextTimeScheduling.Provider
      value={{
        openDialog,
        setOpenDialog,
        contextFormData,
        setcontextFormData,
        selectedViewAs,
        setSelectedViewAs,
      }}
    >
      {children}
    </ContextTimeScheduling.Provider>
  );
};

export { ContextTimeScheduling, TimeSchedulingProvider };
